<script setup lang="ts">
import MarketingHeader from "@/layouts/partials/marketing/MarketingHeader.vue";
import MarketingFooter from "@/layouts/partials/marketing/MarketingFooter.vue";
import DownloadAppModal from "@/components/marketing/DownloadAppModal.vue";
import CustomCursor from "@/components/marketing/CustomCursor.vue";
import { useRootStore } from "@/store/root";
import { useReducedMotion } from "@/composables/use-reduced-motion";

const rootStore = useRootStore();
const { downloadAppModalActive } = storeToRefs(rootStore);
const { prefersReducedMotion } = useReducedMotion();
</script>

<template>
  <div class="bg-decoration flex flex-col items-center w-full">
    <ClientOnly>
      <CustomCursor v-if="!prefersReducedMotion" />
    </ClientOnly>

    <div class="w-full">
      <MarketingHeader />
    </div>

    <main class="w-full p-3 lg:p-0">
      <slot />
    </main>

    <MarketingFooter />
    <ScrollToTop />
    <DownloadAppModal :show="downloadAppModalActive" @close="rootStore.toggleDownloadAppModal(false)" />
  </div>
</template>
