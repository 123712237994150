<script setup lang="ts">
onMounted(() => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
  if (isMobile) return;
  const e = document.querySelector(".cursor-inner") as HTMLElement;
  const t = document.querySelector(".cursor-outer") as HTMLElement;

  const mouseMoveHandler = function (s: MouseEvent) {
    t.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
    e.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
  };

  window.addEventListener("mousemove", mouseMoveHandler);

  document.addEventListener("mouseover", (event) => {
    const target = event.target as HTMLElement;
    const isButtonOrLink =
      target.tagName === "A" || target.tagName === "BUTTON" || target.closest("a") || target.closest("button");
    if (isButtonOrLink || target.classList.contains("cursor-pointer") || target.closest(".cursor-pointer")) {
      e.classList.add("cursor-hover");
      t.classList.add("cursor-hover");
    }
  });

  document.addEventListener("mouseout", (event) => {
    const target = event.target as HTMLElement;
    const isButtonOrLink =
      target.tagName === "A" || target.tagName === "BUTTON" || target.closest("a") || target.closest("button");
    if (isButtonOrLink || target.classList.contains("cursor-pointer") || target.closest(".cursor-pointer")) {
      e.classList.remove("cursor-hover");
      t.classList.remove("cursor-hover");
    }
  });

  e.style.visibility = "visible";
  t.style.visibility = "visible";
});
</script>

<template>
  <div class="mouse-cursor cursor-outer"></div>
  <div class="mouse-cursor cursor-inner"></div>
</template>
